<template>
  <section id="secNews" class="centerContent">
    <h2>{{ $t("headers.news") }}</h2>
    <div id="facebook" class="flexContainerRow facebook">
      <div id="news">
        <h3>{{ $t("headers.messages") }}</h3>
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTodustmetal%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true"
          width="340"
          height="500"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allow="encrypted-media"
        >
        </iframe>
      </div>

      <div id="gigs">
        <h3>{{ $t("headers.events") }}</h3>
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTodustmetal%2F&tabs=events&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true"
          width="340"
          height="500"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allow="encrypted-media"
        >
        </iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.facebook {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 700px) {
  .facebook {
    flex-direction: row;
  }
}

.centerContent {
  text-align: center;
}
</style>
